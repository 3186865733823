import * as React from 'react'
import { isMobile } from 'react-device-detect';
import { GiHamburgerMenu } from 'react-icons/gi';

import ScreenCrowStudiosLogoText from '../images/ScreenCrowStudiosLogoText.png'
import HeaderButton from './headerButton';

const Header = (props) => 
{
    // eslint-disable-next-line no-unused-vars
    const [activeTab, setActiveTab] = React.useState('');
    const [toggled, setToggled] = React.useState(false);

    // Clicked Tab Handling
    const handleButtonClick = (buttonName) => 
    {
        setActiveTab(buttonName);
        props.onButtonClick(buttonName);
        const newUrl = `${window.location.origin}/${buttonName}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        setToggled(false);
    };

    const burgerMenu = () => 
    {
        setToggled(!toggled);
    };

    React.useEffect(() => {
        // Get the path from the URL
        const path = window.location.pathname.split('/')[1].toLowerCase();

        //Change Site Title
        document.title = 'ScreenCrow Studios | ' + path.charAt(0).toUpperCase() + path.slice(1)

        // Set the active tab based on the path
        if (path) 
        {
            setActiveTab(path);
            props.onButtonClick(path);
        }

        const validButtonNames = ['about', 'team', 'home', 'games', 'contact']; 
        if (!validButtonNames.includes(path)) 
        {
            // Redirect to the home screen if the path is invalid
            setActiveTab('home');
            props.onButtonClick('home');
            window.history.pushState({ path: '/home' }, '', '/home');
        } 
        else
            window.history.pushState({ path: `/${path}` }, '', `/${path}`);
    }, [props]);

    // HTML
    if (isMobile) 
    {
        return (
            <header className='bg-black sticky px-10 text-white flex flex-col justify-center items-center py-10'>
                <a href='screencrowstudios.com'>
                    <img src={ScreenCrowStudiosLogoText} width='256' alt='ScreenCrowStudiosLogo'/>
                </a>

                <br/>

                <button onClick={burgerMenu} title={false}>
                    <GiHamburgerMenu />
                </button>

                <div className={`accordion-wrapper flex flex-wrap justify-center w-36 overflow-hidden transition-all duration-500 ${toggled ? 'max-h-screen' : 'max-h-0'}`}>
                    <hr className='w-full my-5'/>

                    <div className='flex flex-wrap justify-center w-full'>
                        <HeaderButton onClick={() => handleButtonClick('about')} name='About' title={false}/>
                        <HeaderButton onClick={() => handleButtonClick('team')} name='Team' title={false}/>
                        <HeaderButton onClick={() => handleButtonClick('home')} name='Home' title={false}/>
                        <HeaderButton onClick={() => handleButtonClick('games')} name='Games' title={false}/>
                        <HeaderButton onClick={() => handleButtonClick('contact')} name='Contact' title={false}/>
                    </div>
                </div>
            </header>
        )
    } 
    else 
    {
        return (
            <header className='bg-black sticky px-10 text-white flex flex-col justify-center items-center py-10'>
                <a href='screencrowstudios.com' className='my-4'>
                    <img src={ScreenCrowStudiosLogoText} width='256' alt='ScreenCrowStudiosLogo' className='md:pe-6'/>
                </a>

                <br/>

                <div className='grid lg:flex justify-center flex-grow'>
                    <HeaderButton onClick={() => handleButtonClick('about')} name='About' title={false}/>
                    <br/>
                    <HeaderButton onClick={() => handleButtonClick('team')} name='Team' title={false}/>
                    <br/>
                    <HeaderButton onClick={() => handleButtonClick('home')} name='Home' title={true}/>
                    <br/>
                    <HeaderButton onClick={() => handleButtonClick('games')} name='Games' title={false}/>
                    <br/>
                    <HeaderButton onClick={() => handleButtonClick('contact')} name='Contact' title={false}/>
                </div>
            </header>
        )
    }
}

export default Header;
