import * as React from 'react'

const Contact = () => 
{
    return(
        <div className='text-left'>
            <h1 className='text-center'>Contact</h1>
            <h3 className='text-center'>For questions job inquires or if you just want to chat please use the appropriate email below.</h3>

            <br/>
            
            <div className='break-all'>
                <li><b><a href='mailto:recruitment@screencrowstudios.com' className='hover:text-[#454545] '>recruitment@screencrowstudios.com</a></b> - Any email related to recruitment or jobs.</li>
                <li><b><a href='mailto:support@screencrowstudios.com' className='hover:text-[#454545]'>support@screencrowstudios.com</a></b> - Any email related to support from us.</li>
                <li><b><a href='mailto:screencrowstudios@screencrowstudios.com' className='hover:text-[#454545]'>screencrowstudios@screencrowstudios.com</a></b> - General emails that don't fall on the above categories.</li>
            </div>
        </div>
    )
}

export default Contact;