import * as React from 'react'

import {BsInstagram, BsTwitterX, BsTiktok, BsYoutube, BsGithub, BsDiscord} from 'react-icons/bs';

const Links =
{
    PressKit:"https://comingsoon",
    Wiki:"https://insanitygame.fandom.com/wiki/INSANITY_Wiki",
    Instagram:"https://www.instagram.com/screencrowstudios/",
    Twitter:"https://twitter.com/ScreenCrowStudi",
    TikTok:"https://www.tiktok.com/@screencrowstudios",
    YouTube:"https://www.youtube.com/channel/UCLkUQ3pHeMa3E18OwpTsi9Q",
    GitHub:"https://github.com/ScreenCrowStudios",
    Discord:"https://discord.com/invite/hXz7fVqvwv"
}

const SocialStyles = 
{
    Text: "text-xs hover:contrast-[.25]",
    Icons:"hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-1"
}

const Footer = () =>
{
    return(
        <footer className='footer bg-[#1e1e1e] center align-middle h-32 inset-x-0 bottom-0 md:px-72'>
            <div className='text-[#6b6b6b] font-bold items-center h-full flex flex-col justify-center'>
                <p>
                    ©{new Date().getFullYear()} ScreenCrow Studios. All rights reserved.
                </p>

                <div className='flex'>
                    <a href={Links.PressKit} target="_blank" rel="noreferrer">
                        <p className={SocialStyles.Text}>Press Kit</p>
                    </a>
                </div>
                
                <div className='flex justify-center flex-row gap-x-2'>
                    <a href={Links.Instagram}> <BsInstagram width='32' className={SocialStyles.Icons}/> </a>
                    <a href={Links.Twitter}> <BsTwitterX width='32' className={SocialStyles.Icons}/> </a>
                    <a href={Links.TikTok}> <BsTiktok width='32' className={SocialStyles.Icons}/> </a>
                    <a href={Links.YouTube}> <BsYoutube width='32' className={SocialStyles.Icons}/> </a>
                    <a href={Links.GitHub}> <BsGithub width='32' className={SocialStyles.Icons}/> </a>
                    <a href={Links.Discord}> <BsDiscord width='32' className={SocialStyles.Icons}/> </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;