import * as React from 'react'

const Home = () => 
{
  return(
    <div>
        <div className='text-center justify-center align-middle'>
            <h2>Welcome To ScreenCrow Studios</h2>
            <br/>
            <p>Use the navigation bar to explore the website!</p>
        </div>
        
        <br/>
        <hr/>
        <br/>

        <div className='flex flex-col justify-right items-center'>
            <p>Join Our Discord Community!</p>

            <iframe src="https://discord.com/widget?id=1260920846126415882&theme=dark" className='h-[27rem] w-[18rem]'
                   allowtransparency="true" frameborder="0" title='Discord'
                   sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
        </div>
    </div>
)
}

export default Home

export const Head = () => <title>ScreenCrow Studios</title>
