import * as React from 'react'
import { Helmet } from "react-helmet";

import Header from '../elements/header.js'
import Body from '../elements/body.js';
import Footer from '../elements/footer.js';

import MetadataImage from '../images/MetadataImage.png'

const IndexPage = () => 
{
    const [activeTab, setActiveTab] = React.useState('');

    //Header Buttons
    const handleButtonClick = (tabName) =>
    {
        setActiveTab(tabName);
    };

    return (
        <>
            <Helmet>
                <meta name="title" content="ScreenCrow Studios"/>
                <meta name="description" content="ScreenCrow is a small, Indie Game Studio and Publisher with people from all around the globe. ScreenCrow Studios was founded in 2023 and it's goal is to create games for people to enjoy, and have fun doing it! Our small team is responsible for Developing, Publishing and Marketing our games."/>
                <meta name="keywords" content="ScreenCrow Studios, ScreenCrow, Indie, Games, Indie Games, The Dreamlurker"/>
                <meta name="robots" content="index, follow"/>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta name="language" content="English"/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="author" content="ScreenCrow Studios"/>

                {/* Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://screencrowstudios.com/" />
                <meta property="og:title" content="ScreenCrow Studios" />
                <meta property="og:description" content="" />
                <meta property="og:image" content={MetadataImage} />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://screencrowstudios.com/" />
                <meta property="twitter:title" content="ScreenCrow Studios" />
                <meta property="twitter:description" content="" />
                <meta property="twitter:image" content={MetadataImage} />
            </Helmet>
            
            <div className='min-h-screen overflow-hidden flex flex-col text-white'>
                <Header onButtonClick={handleButtonClick}/>
                <div className='bg-gradient-to-b from-black to-[#1e1e1e] flex-grow py-10'>
                    <Body activeTab={activeTab}/>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default IndexPage