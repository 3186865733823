import * as React from 'react'

import ScreenCrowStudiosLogo from '../images/ScreenCrowStudiosLogo.png'

const About = () => 
{
    return(
        <div className='text-center'>
            <h1>About</h1>
            <div className='bg-black border-transparent flex items-center justify-center border-8 rounded-3xl sm:mx-16 md:mx-32 lg:mx-52 xl:mx-72 2xl:mx-96'>
                <img src={ScreenCrowStudiosLogo} alt='ScreenCrowStudiosLogo' className='p-10 sm:w-16 md:w-32 lg:w-52 xl:w-72'/>
            </div>
            <br/>
            <p className='break-all'>
                <b>ScreenCrow</b> is a small, Indie Game Studio and Publisher with people from all around the
                globe. ScreenCrow Studios was founded in 2023 and it's goal is to create
                games for people to enjoy, and have fun doing it! Our small team is responsible for <b>Developing</b>,
                <b> Publishing</b> and <b>Marketing</b> our games.
            </p>
            <br/>
        </div>
    )
}

export default About;