import * as React from 'react'

import TheDreamlurker from '../images/games/TheDreamlurker.png'

const Games = () => 
{
    return(
        <div className='page text-center'>
            <h1>Games</h1>

            <div className='relative mx-12'>
                <h1 className="absolute top-[50%] left-0 right-0 text-7xl font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] transform -translate-y-1/2">
                    COMING SOON...
                </h1>
                <img src={TheDreamlurker} alt='the-dreamlurker' className='flex rounded-3xl outline outline-4 outline-black h-[25rem] sm:h-[rem] object-cover object-center'/>
            </div>
        </div>
    )
}

export default Games;