import * as React from 'react'

import About from '../pages/about.js';
import Team from '../pages/team.js';
import Home from '../pages/home.js'
import Games from '../pages/games.js';
import Contact from '../pages/contact.js';

const Body = (props) => 
{
    let {activeTab} = props;
    activeTab = activeTab.toLowerCase()

    return(
        <div className='bg-transparent flex flex-col mx-8 items-center text-center sm:mx-8 md:mx-24 lg:mx-32 xl:mx-40 animate-fade'>
            {/*Show Only Clicked Tab*/}
            {activeTab === 'about' && <About/>}
            {activeTab === 'team' && <Team/>}
            {activeTab === 'home' && <Home/>}
            {activeTab === 'games' && <Games/>}
            {activeTab === 'contact' && <Contact/>}
        </div>
    )
}

export default Body;